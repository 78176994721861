.digital-gift-card {
  .digital-gift-card-main-container {
    @media (max-width: 1276px) {
      grid-template-columns: 10% 80% 10%;
    }
    @media (max-width: 995px) {
      grid-template-columns: 5% 90% 5%;
    }
    @media (max-width: 715px) {
      grid-template-columns: 15% 70% 15%;
    }
    @media (max-width: 600px) {
      grid-template-columns: 10% 80% 10%;
    }
    @media (max-width: 500px) {
      grid-template-columns: 5% 90% 5%;
    }
  }

  .digital-gift-card-main-grid {
    @media (max-width: 1450px) {
      grid-template-columns: 1.3fr 1fr;
      padding-top: 61px;
    }
    @media (max-width: 1350px) {
      grid-template-columns: 1.5fr 1fr;
      padding-bottom: 60px;
    }
    @media (max-width: 1110px) {
      padding-bottom: 40px;
      grid-gap: 30px;
    }
    @media (max-width: 1030px) {
      grid-template-columns: 1.6fr 1fr;
    }
    @media (max-width: 780px) {
      grid-template-columns: 1.7fr 1fr;
      padding-top: 45px;
    }
    @media (max-width: 715px) {
      grid-template-columns: 1fr;
      grid-gap: 14px;
    }
  }

  .digital-gift-card-text-container {
    @media (max-width: 1450px) {
      padding-top: 0;
    }
    @media (max-width: 715px) {
      text-align: center;
    }
  }

  .digital-gift-card-small-line {
    @media (max-width: 715px) {
      display: flex;
      justify-content: center;
    }
  }

  .digital-gift-card-text-h3 {
    @media (max-width: 1110px) {
      font-size: 50px;
      margin-bottom: 20px;
    }
    @media (max-width: 860px) {
      font-size: 45px;
    }
    @media (max-width: 760px) {
      font-size: 40px;
      margin-bottom: 15px;
    }
    @media (max-width: 400px) {
      font-size: 37px;
    }
    @media (max-width: 370px) {
      font-size: 34px;
    }
    @media (max-width: 340px) {
      font-size: 32px;
    }
  }

  .digital-gift-card-text-h5 {
    @media (max-width: 1110px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
    @media (max-width: 760px) {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  .digital-gift-card-p {
    @media (max-width: 1110px) {
      font-size: 16px;
      margin-top: 20px;
      line-height: 1.5;
    }
    @media (max-width: 760px) {
      line-height: 1.4;
    }
  }

  .digital-gift-card-image-container {
    @media (max-width: 1700px) {
      margin-top: 30px;
    }
    @media (max-width: 715px) {
      grid-area: 1;
      width: 282px;
      margin: 0 auto;
    }
    @media (max-width: 500px) {
      width: 250px;
    }
    @media (max-width: 400px) {
      width: 200px;
    }
  }
}
