// @import Group2 from "../assets/illustration/Group2.png";

.case-study-main-container {
  .problem-inner-div-1 {
    margin-left: 98px;
    @media (max-width: 700px) {
      margin-left: 28px;
    }
  }

  .problem-inner-div-2 {
    margin-left: 38px;
    @media (max-width: 700px) {
      margin-left: 8px;
    }
  }

  .case-study-header-item-main {
    @media (max-width: 1700px) {
      width: 100%;
    }
    @media (max-width: 1500px) {
      grid-template-columns: 60px 1fr;
      font-size: 16px;
    }
    @media (max-width: 1100px) {
      grid-template-columns: 50px 1fr;
      font-size: 15px;
    }
    @media (max-width: 875px) {
      grid-gap: 10px;
      font-size: 14px;
    }
    @media (max-width: 738px) {
      display: block;
      text-align: center;
      margin-top: 30px;
      width: 302px;
      font-size: 17px;
      margin: 0 auto;
      margin-bottom: 15px;
      margin-top: 19px;
    }
    @media (max-width: 700px) {
      font-size: 15px;
      width: 264px;
    }
  }

  .case-study-circle-container {
    @media (max-width: 738px) {
      width: 60px;
      margin: 0 auto;
    }
  }

  .case-study-header-display-text {
    @media (max-width: 738px) {
      margin-top: 15px;
    }
  }

  .case-study-header-header-grid {
    @media (max-width: 1700px) {
      grid-gap: 20px;
    }
    @media (max-width: 738px) {
      display: block;
    }
  }

  .case-study-header-image {
    @media (max-width: 1500px) {
      width: 100%;
    }
  }

  .case-study-header-h2 {
    @media (max-width: 1500px) {
      font-size: 55px;
    }
    @media (max-width: 1100px) {
      font-size: 50px;
    }
    @media (max-width: 875px) {
      font-size: 46px;
    }
    @media (max-width: 700px) {
      font-size: 32px;
    }
  }

  .case-study-problem-solution-div {
    @media (max-width: 1300px) {
      grid-gap: 30px;
    }
    @media (max-width: 700px) {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }

  .case-study-see-how-much-div {
    @media (max-width: 1500px) {
      background-size: 100% 100%;
      height: fit-content;
    }
    @media (max-width: 825px) {
      background-size: cover;
    }
    @media (max-width: 500px) {
      background-image: url(https://s3.amazonaws.com/cdn.fraudfix.com/cta-mobile.png);
    }
  }

  .case-study-problem-solution-h4 {
    @media (max-width: 1400px) {
      font-size: 50px;
    }
    @media (max-width: 1100px) {
      font-size: 45px;
    }
    @media (max-width: 875px) {
      font-size: 40px;
      margin-bottom: 20px;
    }
    @media (max-width: 700px) {
      font-size: 30px;
      line-height: 1.2;
      letter-spacing: -0.11px;
    }
  }

  .case-study-problem-solution-p {
    @media (max-width: 1400px) {
      margin-top: 20px;
    }
    @media (max-width: 1100px) {
      font-size: 16px;
    }
    @media (max-width: 875px) {
      font-size: 15px;
      line-height: 1.5;
    }
  }

  .case-study-problem-inner-div {
    @media (max-width: 1100px) {
      font-size: 18px;
      width: 293px;
      height: 100px;
    }
    @media (max-width: 875px) {
      width: 230px;
      height: 80px;
      border-radius: 10px;
      box-shadow: 0 60px 120px 0 rgba(17, 6, 47, 0.1);
      font-size: 17px;
      line-height: 1.18;
      grid-template-columns: 60px 1fr;
    }
    @media (max-width: 700px) {
      text-align: left;
    }
  }

  .case-study-right-grid {
    @media (max-width: 700px) {
      width: 258px;
      margin: 0 auto;
    }
  }

  .case-study-right-grid-solution {
    @media (max-width: 700px) {
      margin: 0 auto;
    }
  }

  .case-study-green-line-div {
    @media (max-width: 700px) {
      display: flex;
      justify-content: center;
      margin-bottom: 35px;
    }
  }

  .case-study-problem-points-item {
    @media (max-width: 875px) {
      width: 100%;
    }
  }

  .case-study-problem-image {
    @media (max-width: 875px) {
      width: 80%;
    }
  }

  .case-study-solution-inner-div {
    @media (max-width: 1100px) {
      font-size: 20px;
      grid-template-columns: 65px 3fr;
      grid-gap: 15px;
    }
    @media (max-width: 875px) {
      width: 100%;
      height: 103px;
    }
    @media (max-width: 500px) {
      display: block;
      font-size: 19px;
      height: auto;
      margin-bottom: 20px;
    }
    @media (max-width: 350px) {
      font-size: 19px;
    }
  }

  .case-study-solution-image-div {
    @media (max-width: 1100px) {
      font-size: 17px;
    }
  }

  .case-study-solution-image {
    @media (max-width: 1100px) {
      width: 100%;
    }
    @media (max-width: 500px) {
      width: 60px;
    }
  }

  .case-study-solution-points-item {
    @media (max-width: 500px) {
      border: 0 !important;
      margin-top: 15px;
    }
  }

  .case-study-about-us-header {
    @media (max-width: 1400px) {
      font-size: 20px;
      margin-bottom: 0;
    }
    @media (max-width: 1100px) {
      font-size: 18px;
    }
    @media (max-width: 700px) {
      font-size: 17px;
      line-height: 1.53;
    }
  }

  .case-study-see-how-much-inner-div {
    @media (max-width: 1500px) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
    @media (max-width: 1000px) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    @media (max-width: 850px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  #backgroundImageContainer {
    position: relative;
    min-height: 100%;
    // overflow: hidden;
  }

  #backgroundImageContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
  }

  .case-study-about-us-div {
    @media (max-width: 1500px) {
      grid-gap: 50px;
    }
    @media (max-width: 1400px) {
      font-size: 50px;
    }
    @media (max-width: 700px) {
      grid-template-columns: 1fr;
      text-align: center;
      max-width: 459px;
    }
  }

  .case-study-left-grid-item {
    @media (max-width: 1200px) {
      width: 100%;
    }
    @media (max-width: 700px) {
      grid-area: 2;
      max-width: 459px;
      margin: 0 auto;
    }
  }

  .case-study-large-screen {
    @media (max-width: 700px) {
      display: none;
    }
  }

  .case-study-mobile {
    @media (min-width: 701px) {
      display: none;
    }
  }

  .case-study-see-how-much-h4 {
    @media (max-width: 1000px) {
      font-size: 40px;
    }
    @media (max-width: 850px) {
      font-size: 37px;
      width: 433px;
    }
    @media (max-width: 700px) {
      font-size: 30px;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: -0.11px;
      margin-bottom: 20px;
    }
    @media (max-width: 550px) {
      width: 100%;
    }
  }

  .case-study-see-how-much-button {
    @media (max-width: 700px) {
      width: 131px;
      height: 45px;
      border-radius: 32.5px;
      font-size: 15px;
    }
  }

  .case-study-main-style-container {
    @media (max-width: 1000px) {
      padding-bottom: 50px !important;
    }
    @media (max-width: 700px) {
      padding-top: 140px !important;
    }
  }

  .case-study-solution-data-number {
    @media (max-width: 500px) {
      left: 52%;
      font-size: 15px;
    }
  }
}

.case-study-ashford-main {
  .case-study-large-company-image {
    max-width: 632px;
    padding-top: 50px;
    @media (max-width: 1175px) {
      padding-top: 92px;
    }
    @media (max-width: 700px) {
      padding-top: 0px;
    }
    @media (max-width: 550px) {
      padding-top: 0px;
    }
  }

  .case-study-about-us-image-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
      align-items: flex-end;
    }
    @media (max-width: 700px) {
      display: block;
    }
  }

  .case-study-about-us-div {
    @media (max-width: 700px) {
      grid-gap: 20px;
    }
  }
}
