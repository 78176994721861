@media only screen and (max-width: 1750px) {
  .blogItemImage {
    width: 312px !important;
    height: 200px !important;
  }

  .blogItemPageGrid {
    grid-template-rows: 0.7fr 0.3fr 0.3fr !important;
    grid-template-columns: 35% 35% 30% !important;
  }
}

@media only screen and (max-width: 1600px) {
  .inputGridDiv {
    grid-template-columns: 350px 300px !important;
  }

  .calculatorInputLabel {
    font-size: 15px !important;
  }

  .calculatorAdditionalText {
    font-size: 12px !important;
  }

  .calculatorButtonDiv {
    width: 280px !important;
  }

  .calculatorButtonGrid {
    grid-template-columns: 284px 350px !important;
  }
}

@media only screen and (max-width: 1555px) {
  .blogItemPageGrid {
    grid-template-rows: 0.7fr 0.3fr 0.3fr !important;
    grid-template-columns: 40% 40% 20% !important;
  }

  .blogItemMessageDiv {
    height: 180px !important;
  }

  .copyright {
    grid-template-columns: 45% 55% !important;
  }
}

@media only screen and (max-width: 1523px) {
  .blogMessageDiv {
    height: 275px !important;
  }
}

@media only screen and (max-width: 1500px) {
  .homeOneApiInfo {
    grid-template-columns: 5% 90% 5% !important;
  }

  .divForImage {
    width: 100% !important;
  }

  .leftColumn {
    padding-top: 158px !important;
  }

  .navbarUl {
    padding-left: 0 !important;
  }

  .howItWorksPageGrid3 {
    grid-template-columns: 15% 70% 15% !important;
  }
}

@media only screen and (max-width: 1461px) {
  .blogMessageDiv {
    height: 290px !important;
  }

  .postInMessageDiv {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1445px) {
  .inputGridDiv {
    grid-template-columns: 325px 200px !important;
  }

  .calculatorMainH3 {
    font-size: 35px !important;
  }

  .calculatorButtonP {
    font-size: 22px !important;
  }

  .calculatorButtonDiv {
    width: 240px !important;
    height: 65px !important;
  }

  .calculatorButton {
    width: 161px !important;
    height: 51px !important;
  }

  .calculatorTotalSpan {
    font-size: 22px !important;
  }

  .calculatorButtonGrid {
    grid-template-columns: 249px 345px !important;
    margin-top: 35px !important;
  }
}

@media only screen and (max-width: 1415px) {
  .blogItemImage {
    width: 265px !important;
    height: 170px !important;
  }

  .blogItemPageGrid {
    grid-template-rows: 0.7fr 0.3fr 0.3fr !important;
    grid-template-columns: 35% 35% 30% !important;
  }

  .navbarUl {
    grid-template-columns: 1.3fr 1fr 0.9fr 0.7fr 1.2fr 1fr !important;
  }

  .copyrightFooter {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1355px) {
  .aboutPageGrid3 {
    grid-template-columns: 12% 76% 12% !important;
  }

  .navbar-grid {
    grid-template-columns: 40% 60% !important;
  }

  .footerGrid {
    grid-template-columns: 50% 50% !important;
  }

  .blogHeader {
    grid-template-columns: 1fr 1.5fr 1fr !important;
  }

  .dateImageDiv {
    grid-template-columns: 7% 48% 45% !important;
  }

  .searchDiv {
    grid-template-columns: 1fr 4fr 1fr !important;
  }

  .gridForOverlapLarge {
    grid-template-rows: 1fr 0.7fr 0.7fr !important;
  }

  .gridForOverlapSmall {
    grid-template-rows: 1fr 1.3fr 1fr !important;
  }

  .mainDivTechnology {
    margin-top: -151px !important;
  }

  .homeOneTechnologyP {
    width: 60% !important;
  }

  .apiInfoGrid2 {
    grid-template-columns: 30% 70%;
  }

  .blogItemPageGrid {
    grid-template-rows: 1fr 1fr 0.4fr !important;
  }

  .ratesMainDiv {
    width: 100% !important;
  }

  .calculatorGrid2 {
    grid-template-columns: 65% 35% !important;
  }

  .copyrightP {
    font-size: 16px !important;
  }

  .copyright {
    grid-template-columns: 40% 60% !important;
  }

  .copyrightFooter {
    grid-gap: 8px !important;
  }
}

@media only screen and (max-width: 1305px) {
  .blogItemPageGrid {
    grid-template-rows: 0.7fr 0.3fr 0.3fr !important;
    grid-template-columns: 40% 40% 20% !important;
  }
}

@media only screen and (max-width: 1286px) {
  .blogMessageP {
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 1276px) {
  .searchDiv {
    grid-template-columns: 10% 80% 10% !important;
  }

  .gridMaster {
    padding-top: 70px !important;
  }

  .navbarUl {
    grid-template-columns: 1.4fr 1fr 0.9fr 0.7fr 1.2fr 1fr !important;
  }

  .blogItemMain,
  .blogItemMainContent,
  .blogMain,
  .pricingMain,
  .footerMain,
  .navroot {
    grid-template-columns: 10% 80% 10% !important;
  }
}

@media only screen and (max-width: 1226px) {
  .navbar-grid {
    grid-template-columns: 35% 65% !important;
  }

  .homeOneMainDiv {
    padding-top: 36px !important;
  }

  .searchDiv {
    height: 160px !important;
    padding-top: 17px !important;
  }

  .navbarUl {
    font-size: 16px !important;
    grid-gap: 0px !important;
  }

  .calculatorInput {
    width: 180px !important;
  }
}

@media only screen and (max-width: 1176px) {
  .homeOneMainDiv {
    grid-template-columns: 10% 80% 10% !important;
  }

  .copyrightFooter {
    grid-gap: 0 !important;
  }

  .leftColumn {
    padding-top: 136px !important;
  }

  .theImagesWhatWeDo {
    width: 100% !important;
  }

  .twoColumnDivWhatWeDo {
    grid-gap: 25px !important;
  }

  .contactUsContentP {
    width: 100% !important;
  }

  .ratesMainDiv {
    height: initial !important;
  }

  .calculatorInput {
    height: 40px !important;
  }

  .pricingMainContent {
    grid-template-columns: 7% 86% 7% !important;
  }
}

@media only screen and (max-width: 1148px) {
  .dateImageDiv {
    grid-template-columns: 7% 55% 37% !important;
  }

  .copyrightP {
    font-size: 15px !important;
  }

  .mainDivTechnology {
    margin-top: -170px !important;
  }

  .outerDivTechnology {
    padding-top: 175px !important;
  }

  .gridForOverlapSmall .postInMessageDiv {
    font-size: 14px;
  }

  .rateHeaderP {
    font-size: 28px !important;
  }

  .rateSmallP {
    font-size: 15px !important;
  }

  .ratesMainDiv {
    grid-template-columns: 7% 90% 3% !important;
  }

  .calculatorGrid2 {
    grid-template-columns: 60% 40% !important;
  }
}

@media only screen and (max-width: 1135px) {
  .divForImage {
    width: 90% !important;
  }

  .homeH3Apis {
    width: 70% !important;
  }

  .gridForOverlapSmall .blogMessageP {
    font-size: 21px !important;
  }
}

@media only screen and (max-width: 1135px) {
  .contactUsGrid3 {
    grid-template-columns: 5% 90% 5% !important;
  }

  .welcomeHeader {
    font-size: 50px !important;
  }

  .aboutPageGrid3 {
    grid-template-columns: 8% 84% 8% !important;
  }

  .gridForOverlapSmall .blogMessageP {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1045px) {
  .calculatorInput {
    width: 165px !important;
  }
}

@media only screen and (max-width: 1030px) {
  .homeOneMainDiv {
    grid-template-columns: 5% 90% 5% !important;
  }

  .gridForOverlapLarge .blogMessageDiv {
    height: 295px !important;
    padding-bottom: 20px !important;
  }
}

@media only screen and (max-width: 995px) {
  .blogItemMain,
  .blogItemMainContent,
  .contactusMainGrid,
  .blogMain,
  .pricingMain,
  .footerMain,
  .navroot,
  .contactUsGrid3,
  .homeOneMainDiv {
    grid-template-columns: 5% 90% 5% !important;
  }

  .techologyGridThree {
    grid-template-columns: 0.1fr 1fr 1fr 1fr 0.1fr !important;
  }

  .techologyGridTwo {
    grid-template-columns: 0.5fr 1fr 1fr 0.5fr !important;
  }

  .homeOneTechnologyP {
    width: 55% !important;
  }

  .divForImage {
    width: 82% !important;
    text-align: right;
    padding-right: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin: 0 auto !important;
  }

  .leftColumn {
    padding-top: 128px !important;
  }

  .gridForOverlapLarge {
    grid-template-rows: 1fr 1fr 0.7fr !important;
  }

  .blogItemMessageDiv {
    height: 230px !important;
  }

  .gridForOverlapSmall .blogMessageDiv {
    height: 306px !important;
  }

  .inputGridDiv {
    grid-template-columns: 60% 40% !important;
  }

  .calculatorButtonGrid {
    grid-template-columns: 60% 40% !important;
  }

  .calculatorButtonDiv {
    width: 100% !important;
  }

  .calculatorDollarDiv {
    grid-template-columns: 10% 90% !important;
  }

  .calculatorInput {
    width: 100% !important;
  }

  .calculatorGrid2 {
    grid-gap: 20px;
  }
}

@media only screen and (max-width: 946px) {
  .blogItemImage {
    width: 218px !important;
    height: 140px !important;
  }

  .blogItemPageGrid {
    grid-template-rows: 0.6fr 0.5fr 0.3fr !important;
    grid-template-columns: 35% 35% 30% !important;
  }

  .gridForOverlapSmall .blogMessageP {
    font-size: 20px !important;
  }

  .dateImageDiv {
    grid-template-columns: 7% 59% 33% !important;
    padding-top: 0 !important;
  }

  .aboutUsLineBreak {
    display: none !important;
  }

  .pricingMainContent {
    grid-template-columns: 7% 86% 7% !important;
  }
}

@media only screen and (max-width: 918px) {
  .gridMaster {
    grid-template-columns: 1fr 0.7fr !important;
  }

  .gridForOverlapLarge .blogMessageP {
    font-size: 25px !important;
  }
}

@media only screen and (max-width: 888px) {
  .gridMaster {
    grid-template-columns: 1fr 0.7fr !important;
  }

  .divForImage {
    width: 100% !important;
    margin: 0 !important;
  }

  .navbarUl {
    padding: 0;
  }

  .iconGrid {
    width: 88% !important;
  }

  .apiInfoGrid3 {
    width: 100% !important;
  }

  .howItWorksPageGrid3 {
    grid-template-columns: 10% 80% 10% !important;
  }

  .phoneDiv {
    display: grid;
    grid-template-columns: 1fr 5fr;
  }

  .contactUsGrid2 {
    grid-gap: 20px;
  }

  .blogHeader {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .dateImageDiv {
    grid-template-columns: 7% 59% 33% !important;
    padding-top: 0;
  }

  .mainDivTechnology {
    margin-top: -177px !important;
  }
}

@media only screen and (max-width: 848px) {
  .navbarUl {
    font-size: 15px !important;
  }

  .blogItemPageGrid {
    grid-template-rows: 0.6fr 0.5fr 0.3fr !important;
    grid-template-columns: 40% 40% 20% !important;
  }

  .footerGrid {
    grid-template-columns: 55% 45% !important;
  }

  .gridForOverlapSmall {
    grid-template-rows: 1fr 1.3fr 1fr !important;
  }

  .mainDivTechnology {
    margin-top: -190px !important ;
  }

  .contactUsGrid2 {
    display: block !important;
    text-align: center !important;
  }

  .successEmail {
    margin-left: 40px !important;
    margin-bottom: 0 !important;
    margin-top: 30px !important;
  }

  .smallLineContactUs {
    margin: 0 auto !important;
  }

  .phoneDiv {
    display: block !important;
    margin-top: 30px !important;
  }

  .contactUsFormDiv {
    display: block !important;
    text-align: left !important;
    padding-left: 40px !important;
  }

  .inputItem {
    padding-top: 30px !important;
  }

  .inputTextarea {
    margin-top: 30px !important;
  }

  .inputGridDiv {
    grid-template-columns: 55% 45% !important;
  }
}

@media only screen and (max-width: 832px) {
  .navbarUl {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 816px) {
  .pricingMainDiv,
  .homeOneApiInfo,
  .homeOneHowItWorks {
    grid-template-columns: 5% 90% 5% !important;
  }

  .copyrightFooter {
    display: none !important;
  }

  .copyright {
    display: block !important;
  }

  .copyrightP {
    padding-top: 30px !important;
  }

  .homeOneTechnologyP {
    width: 80%;
  }

  .mainDivTechnology {
    margin-top: -210px !important;
  }

  .pricingGrid4 {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .outerDivTechnology {
    padding-top: 185px !important;
  }
}

@media only screen and (max-width: 795px) {
  .blogItemImage {
    width: 312px !important;
    height: 200px !important;
    display: block !important;
  }

  .blogItemPageGrid {
    display: block !important;
    text-align: center !important;
    margin-bottom: 157px !important;
    position: relative !important;
    display: inline-block !important;
  }

  .blogItemsGrid3 {
    display: block !important;
    text-align: center !important;
  }

  .blogItemMessageDiv {
    width: 250px !important;
    height: 180px !important;
    text-align: center;
  }

  .messageDivOuterDiv {
    text-align: center !important;
    position: absolute !important;
    top: 116%;
    right: -19%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 786px) {
  .gridMaster {
    grid-template-columns: 1fr 0.6fr !important;
  }

  .gridForOverlapSmall .blogMessageP {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 774px) {
  .navbar-grid {
    padding-bottom: 24px;
  }

  .footerGrid {
    grid-template-columns: 60% 40% !important;
  }

  .bigFraudFixLogo {
    display: none;
  }

  .smallFraudFixLogo {
    display: initial !important;
  }

  .navbarUl {
    margin-bottom: 0 !important;
  }

  .navbarLogo {
    align-self: center;
  }

  .gridMaster {
    grid-template-columns: 1fr 0.6fr !important;
  }

  .leftColumn {
    padding-top: 100px !important;
  }

  .divForImage {
    padding-top: 53px;
  }

  .blogHeader {
    grid-template-columns: 1fr 0.7fr 1fr !important;
  }

  .calculatorGrid2 {
    display: block !important;
  }

  .calculatorFinePrint {
    display: none;
  }

  .calculatorFinePrintForMobile {
    display: block !important;
    width: 100% !important;
  }

  .ratesDivGridForMobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px;
  }

  .rateHeaderP {
    text-align: center !important;
    font-size: 24px !important;
  }

  .rateSmallP {
    font-size: 14px !important;
  }

  .rateTinyP {
    font-size: 12px !important;
  }

  .ratesGreenNumber {
    font-size: 22px !important;
  }

  .calculatorInput {
    height: 30px !important;
  }

  .calculatorInputDiv {
    margin: 0 !important;
  }

  .calculatorButtonGrid {
    margin-top: 10px !important;
    grid-template-columns: 55% 45% !important;
  }

  .calculatorMainH3 {
    margin-bottom: 15px !important;
    font-size: 30px !important;
  }

  .calculatorInputLabel {
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }

  .calculatorButtonP {
    font-size: 19px !important;
  }

  .calculatorAverageTicket {
    font-size: 13px !important;
  }

  .calculatorButtonDiv {
    height: 40px !important;
    width: 80% !important;
  }

  .calculatorDollarDiv {
    width: 80% !important;
  }

  .calculatorTotalSpan {
    padding-top: 1px !important;
    padding-right: 57px !important;
  }
}

@media only screen and (max-width: 768px) {
  .leftColumn input {
    width: 290px !important;
  }
}

@media only screen and (max-width: 738px) {
  .navbarUl {
    display: none !important;
  }

  .mobileDrawer {
    display: block !important;
  }

  .navbarMenuList {
    justify-self: end;
  }
}

@media only screen and (max-width: 722px) {
  .leftColumn {
    padding-top: 20px !important;
  }

  .welcomeHeader {
    font-size: 40px !important;
  }

  .gridMaster {
    display: block !important;
    text-align: center !important;
  }

  .rightSideImage {
    display: none !important;
  }

  .footerGrid {
    display: block !important;
    text-align: center;
  }

  .line {
    display: none !important;
  }

  .iconsLargeScreen {
    display: none !important;
  }

  .carousel {
    margin: 0 auto !important;
  }

  .carousel .slide {
    background-color: inherit;
  }

  .homeOneMainDiv {
    padding-bottom: 35px !important;
  }

  .theButton {
    display: none !important;
  }

  .mobileButton {
    display: inline-block !important;
  }

  .welcomeHeader {
    width: 67%;
    margin: 0 auto;
  }

  .smallIconsWelcome {
    display: initial !important;
  }

  .twoColumnDivWhatWeDo {
    display: none !important;
  }

  .mobileArrayDiv {
    display: initial !important;
  }

  .theImagesWhatWeDo {
    width: inherit !important;
    text-align: center;
  }

  .theDivForWhatWeDoImage {
    padding-top: 35px;
    text-align: center;
  }

  .gridOfImagesTechnology {
    display: none !important;
  }

  .togglerForMobileDiv {
    display: initial !important;
  }

  .outerDivTechnology {
    overflow: hidden !important;
  }

  .mobileGraphImage {
    width: 1000px;
  }

  .innerDivRowTechnology,
  .techologyGridTwo,
  .techologyGridThree {
    display: initial !important;
    text-align: center !important;
  }

  .arrayOfTagsTechnology {
    margin: 0 auto !important;
  }

  .iconGrid {
    grid-template-columns: 1fr 1fr !important;
  }

  .apiInfoGrid2,
  .apiInfoGrid3 {
    display: block !important;
    margin: 0 auto;
    text-align: center !important;
  }

  .apiInfoGrid2 {
    padding-bottom: 20px;
  }

  .apiP {
    padding-top: 20px;
  }

  .homeOneHowItWorks {
    display: block !important;
    text-align: center;
  }

  .footerGrid3 {
    display: block !important;
  }

  .largeScreenFooterLogo {
    display: none !important;
  }

  .mobileFooterLogo {
    display: block !important;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .footerGridLogos {
    display: block !important;
    text-align: center;
    padding-top: 20px;
  }

  .socialLogos {
    padding: 15px;
  }

  .copyright {
    text-align: center;
    width: 37%;
    margin: 0 auto;
    padding-top: 20px;
  }

  .pricingChart {
    margin-top: -240px !important;
  }

  .mobileBlogPage {
    display: none;
  }

  .blogMainContent {
    display: block !important;
  }

  .inputForMobile {
    width: 300px;
  }

  .blogPageMainContent,
  .pricingMainContent,
  .aboutPageGrid3 {
    grid-template-columns: 5% 90% 5% !important;
  }

  .pricingGrid4 {
    grid-gap: 20px !important;
  }

  .gridForOverlapLarge {
    grid-template-rows: 1fr 0.6fr 0.7fr !important;
  }

  .gridForOverlapSmall {
    grid-template-rows: 1fr 1.3fr 1fr !important;
  }

  .gridForOverlapLarge .blogMessageDiv {
    height: 225px !important;
  }

  .gridForOverlapSmall .postInMessageDiv {
    display: initial;
  }

  .dateImageDiv {
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 704px) {
  .rateSmallP {
    width: 80% !important;
  }
}

@media only screen and (max-width: 690px) {
  .mainDivTechnology {
    margin-top: -250px !important;
  }

  .outerDivTechnology {
    padding-top: 220px !important;
  }

  .aboutusGrid2 {
    display: block !important;
  }
}

@media only screen and (max-width: 690px) {
  .pricingChart {
    grid-template-columns: 1fr 1fr !important;
  }

  .hideMobile {
    display: none !important;
  }

  .blogHeader {
    grid-template-columns: 1fr 0.5fr 1fr !important;
  }
}

@media only screen and (max-width: 674px) {
  .calculatorTotalSpan {
    padding-right: 20px !important;
  }
}
@media only screen and (max-width: 629px) {
  .blogHeader {
    grid-template-columns: 1fr 0.3fr 1fr !important;
  }

  .rateTotalSavings {
    display: block !important;
    margin-bottom: 6px !important;
  }

  .ratesMainDiv {
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .mainDivTechnology {
    margin-top: -287px !important;
  }

  .gridHowItWorksPage {
    display: block !important;
  }

  .outerDivTechnology {
    padding-top: 240px !important;
  }

  .pricingGrid4 {
    grid-template-columns: 1fr 1fr !important;
  }

  .copyright {
    width: 50%;
  }

  .blogMessageP {
    font-size: 30px !important;
  }

  .gridForOverlapLarge {
    grid-template-rows: 1fr 0.8fr 0.7fr !important;
  }

  .calculatorTotalSpan {
    padding-right: 15px !important;
  }

  .calculatorButtonDiv {
    width: 90% !important;
  }

  .calculatorDollarP {
    margin-left: 24px !important;
  }

  .calculatorInput {
    padding-right: 15px !important;
  }

  .calculatorButtonP {
    line-height: initial !important;
  }
}

@media only screen and (max-width: 580px) {
  .blogHeader {
    grid-template-columns: 1fr 0.1fr 1fr !important;
  }
}

@media only screen and (max-width: 531px) {
  .blogListShare {
    display: none;
  }

  .blogMessageP {
    font-size: 28px !important;
  }

  .gridForOverlapLarge {
    grid-template-rows: 1fr 1fr 0.7fr !important;
  }

  .calculatorButtonDiv {
    width: 100% !important;
  }

  .calculatorButtonP {
    margin-bottom: 0 !important;
  }

  .calculatorDollarP {
    margin-left: 20px !important;
  }

  .calculatorInput {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 496px) {
  .gridForOverlapSmall .blogMessageP {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 470px) {
  .calculatorDollarDiv {
    width: 100% !important;
  }

  .calculatorDollarP {
    margin-left: 30px !important;
  }

  .calculatorTotalSpan {
    font-size: 19px !important;
    padding-top: 3px !important;
  }

  .ratesGreenNumber {
    font-size: 17px !important;
  }

  .rateSmallP {
    width: 100% !important;
  }

  .rateTotalSavings {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 460px) {
  .mainDivTechnology {
    margin-top: -353px !important;
  }

  .homeOneTechnologyP {
    width: 75% !important;
  }

  .outerDivTechnology {
    padding-top: 339px !important;
  }

  .copyright {
    width: 58%;
  }

  .pricingChart {
    margin-top: -171px !important;
  }

  .gridForOverlapLarge {
    grid-template-rows: 1fr 1fr 0.9fr !important;
  }
}

@media only screen and (max-width: 400px) {
  .gridForOverlapLarge {
    grid-template-rows: 1fr 1.1fr 1fr !important;
  }

  .mainDivTechnology {
    margin-top: -412px !important;
  }

  .outerDivTechnology {
    padding-top: 400px !important;
  }

  .pricingGrid4 {
    grid-gap: 10px !important;
  }

  .blogMessageP {
    font-size: 24px !important;
  }

  .contactUsFormDiv {
    text-align: center !important;
    padding-left: 0px !important;
  }

  .successEmail {
    margin-left: 0 !important;
  }

  .gridForOverlapLarge .blogMessageP {
    font-size: 21px !important;
  }

  .calculatorButtonGrid {
    grid-template-columns: 50% 50% !important;
  }
}

@media only screen and (max-width: 388px) {
  .theInput {
    width: 270px !important;
  }

  .copyright {
    width: 65%;
  }

  .blogItemImage {
    max-width: 290px !important;
  }

  .messageDivOuterDiv {
    right: -29% !important;
  }

  .rateSmallP {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 365px) {
  .calculatorInput {
    font-size: 15px !important;
  }

  .calculatorTotalSpan {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 355px) {
  .gridForOverlapLarge {
    grid-template-rows: 1fr 1.3fr 1fr !important;
  }

  .theInput {
    display: none !important;
  }

  .mobileButton {
    display: none !important;
  }

  .blogHeader {
    grid-template-columns: 1fr 0.1fr 0.8fr !important;
  }

  .ratesGreenNumber {
    font-size: 15px !important;
  }

  .calculatorButtonP {
    font-size: 17px !important;
  }

  .calculatorButtonGrid {
    grid-template-columns: 55% 45% !important;
  }

  .rateSmallP {
    font-size: 12px !important;
  }

  .rateTotalSavings {
    font-size: 13px !important;
  }

  .calculatorTotalSpan {
    font-size: 16px !important;
    padding-top: 6px !important;
  }

  .calculatorDollarP {
    margin-left: 22px !important;
  }
}

@media only screen and (max-width: 335px) {
  .copyright {
    width: 75%;
  }

  .calculatorInputLabel {
    font-size: 13px !important;
  }

  .calculatorTotalSpan {
    padding-right: 10px !important;
  }

  .calculatorButtonGrid {
    grid-template-columns: 50% 50% !important;
    grid-gap: 6px !important;
  }

  .rateHeaderP {
    font-size: 23px !important;
  }

  .ratesMainDiv {
    padding-top: 10px !important;
  }
}

@media only screen and (max-width: 319px) {
  .calculatorInput {
    font-size: 14px !important;
  }

  .calculatorMobileApproximateRateDiv {
    display: block !important;
  }

  .ratesMainDiv {
    display: none !important;
  }
}

@media only screen and (max-width: 305px) {
  .calculatorInputLabel {
    font-size: 11.5px !important;
  }

  .calculatorInput {
    font-size: 13px !important;
  }

  .inputGridDiv {
    grid-template-columns: 53% 47% !important;
  }

  .calculatorDollarP {
    font-size: 13px !important;
  }

  .calculatorMainH3 {
    font-size: 25px !important;
  }

  .calculatorAdditionalText {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 300px) {
  .carousel.carousel-slider {
    width: 90% !important;
  }

  .whatWeDoBigImage {
    width: 90% !important;
  }

  .whatWeDoP {
    font-size: 40px !important;
  }

  .whatWeDoDiv {
    grid-template-columns: 8% 84% 8% !important;
  }

  .technologyH3 {
    font-size: 40px !important;
  }

  .welcomeHeader {
    font-size: 34px !important;
  }

  .MuiDrawer-root .MuiPaper-root {
    width: 70%;
  }
}

@media only screen and (max-width: 850px) {
  .mediumDisplayNone {
    display: none;
  }

  .mediumScreenSmallerGrid {
    grid-template-columns: 25px 1fr 1fr 1fr 1fr 1fr 25px !important;
  }
}
