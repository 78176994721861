.landing-page-main-container {
  .landing-page-main {
    @media (max-width: 1840px) {
      width: 80%;
      margin-bottom: 90px;
    }
    @media (max-width: 1300px) {
      grid-gap: 53px;
      width: 85%;
    }
    @media (max-width: 1165px) {
      grid-gap: 41px;
      width: 88%;
    }

    @media (max-width: 975px) {
      grid-template-columns: 1.2fr 1fr;
    }
    @media (max-width: 915px) {
      grid-gap: 27px;
    }
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      padding-top: 30px;
    }
    @media (max-width: 375px) {
      width: 100%;
    }
  }

  .landing-page-right-div {
    @media (max-width: 800px) {
      grid-area: 1;
    }
    @media (max-width: 375px) {
      width: 90%;
      margin: 0 auto;
    }
  }

  .landing-right-div-wrapper {
    @media (max-width: 800px) {
      width: 449px;
      text-align: center;
      margin: 0 auto;
    }
    @media (max-width: 525px) {
      width: 368px;
    }
    @media (max-width: 425px) {
      width: 326px;
    }
    @media (max-width: 375px) {
      width: 100%;
    }
  }

  .landing-page-left-div {
    @media (max-width: 800px) {
      display: none;
      width: 449px;
      text-align: center;
      margin: 0 auto;
      margin-top: 31px;
    }
    @media (max-width: 525px) {
      width: 368px;
      margin: 15px 0 0 auto;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
    @media (max-width: 375px) {
      width: 94%;
    }
  }

  .landing-page-h3 {
    @media (max-width: 1400px) {
      font-size: 53px;
    }
    @media (max-width: 1300px) {
      font-size: 50px;
      margin-top: 11%;
    }
    @media (max-width: 1045px) {
      font-size: 42px;
    }
    @media (max-width: 975px) {
      font-size: 39px;
    }
    @media (max-width: 890px) {
      font-size: 35px;
    }
    @media (max-width: 800px) {
      font-size: 43px;
      margin-top: 72px;
    }
    @media (max-width: 525px) {
      font-size: 32px;
    }
  }

  .landing-page-pricing-p {
    @media (max-width: 1300px) {
      margin-bottom: 38px;
    }
    @media (max-width: 525px) {
      font-size: 16px;
      width: 205px;
      margin: 28px auto 37px auto;
    }
  }

  .landing-page-case-study-div {
    @media (max-width: 1600px) {
      width: 75%;
    }
    @media (max-width: 1365px) {
      width: 80%;
    }
    @media (max-width: 1275px) {
      width: 85%;
    }
    @media (max-width: 1135px) {
      width: 75%;
    }
    @media (max-width: 800px) {
      width: 85%;
    }
    @media (max-width: 700px) {
      width: 348px;
      text-align: center;
      padding-bottom: 35px;
    }
    @media (max-width: 400px) {
      width: 80%;
    }
  }

  .landing-page-success-item {
    @media (max-width: 1300px) {
      width: 100%;
    }
    @media (max-width: 700px) {
      padding-bottom: 20px;
    }
  }

  .landing-page-success-item.item2 {
    @media (max-width: 1135px) {
      display: none;
    }
    @media (max-width: 700px) {
      display: block;
    }
  }

  .landing-page-rounded-div {
    @media (max-width: 1365px) {
      width: 285px;
      height: 84px;
    }
    @media (max-width: 1165px) {
      font-size: 14px;
      width: 252px;
      height: 72px;
      bottom: -7%;
    }
    @media (max-width: 915px) {
      width: 235px;
      height: 61px;
    }
    @media (max-width: 830px) {
      width: 226px;
      height: 61px;
    }
    @media (max-width: 400px) {
      position: absolute;
      right: 16.5%;
      bottom: -60px;
    }
  }

  .landing-page-h4 {
    @media (max-width: 1165px) {
      font-size: 16px;
    }
  }

  .landing-page-case-study-grid {
    @media (max-width: 1200px) {
      font-size: 12px;
    }
    @media (max-width: 1135px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 700px) {
      grid-template-columns: 1fr;
      font-size: 13px;
      grid-gap: 0;
    }
  }

  .landing-page-bottom-grid {
    @media (max-width: 700px) {
      display: block;
    }
  }

  .landing-page-button-div {
    @media (max-width: 975px) {
      width: 100%;
    }
    @media (max-width: 875px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      width: 230px;
      padding-left: 30px;
      padding-right: 30px;
      height: 72px;
      margin: 0 auto;
      border-radius: 41.5px;
    }
  }

  .landing-fraudfix-logo-large {
    @media (max-width: 800px) {
      display: none;
    }
  }

  .landing-fraudfix-logo-mobile {
    @media (min-width: 801px) {
      display: none;
    }
  }

  .landing-page-header-h5 {
    @media (max-width: 1100px) {
      font-size: 28px;
    }
    @media (max-width: 1000px) {
      font-size: 23px;
    }
  }

  .landing-page-bHeader-grid {
    @media (max-width: 1100px) {
      grid-template-columns: 40px 1fr;
      grid-row-gap: 5px;
    }
    @media (max-width: 800px) {
      text-align: left;
    }
  }

  .landing-page-bHeader-p {
    @media (max-width: 1100px) {
      font-size: 14px;
      line-height: 1.2;
    }
  }
}

.landing-page-technology {
  @media (max-width: 1650px) {
    background-position: -44px -51px !important;
  }
  @media (max-width: 1500px) {
    background-position: -44px -18px !important;
    background-size: 104% !important;
  }
  @media (max-width: 1400px) {
    background-position: -58px -18px !important;
    background-size: 105% !important;
  }
  @media (max-width: 1200px) {
    background-position: -58px 0px !important;
    padding-bottom: 0;
    background-size: 106% !important;
  }
  @media (max-width: 1100px) {
    background-position: -62px 35px !important;
  }
  @media (max-width: 1025px) {
    background-size: auto 100% !important;
    background-position: -68px 33px !important;
    height: 887px;
  }
  @media (max-width: 800px) {
    height: auto;
  }

  .technology-outer-div {
    @media (max-width: 1300px) {
      padding-top: 90px;
    }
  }

  .technology-pop-out-first-p {
    @media (max-width: 1475px) {
      width: 465px;
    }
  }

  .technology-pop-out-main {
    @media (max-width: 1475px) {
      grid-template-columns: 7.5% 85% 7.5%;
    }
    @media (max-width: 1100px) {
      grid-template-columns: 5% 90% 5%;
    }
    @media (max-width: 875px) {
      grid-template-columns: 2% 96% 2%;
    }
  }

  .technology-pop-out-first-item-grid {
    @media (max-width: 1500px) {
      margin-top: 60px;
    }
    @media (max-width: 1175px) {
      margin-top: 40px;
    }
  }

  .technology-pop-out-image-div1 {
    margin-top: 10px;
  }

  .technology-pop-out-div-row {
    @media (max-width: 1030px) {
      grid-template-columns: 20% 80%;
    }
  }
}

.landing-page-technology.mobile {
  @media (min-width: 801px) {
    display: none;
  }
}

.landing-page-technology.large-screen {
  @media (max-width: 800px) {
    display: none;
  }
}

.landing-page-api-container {
  .landing-page-button-div {
    @media (max-width: 500px) {
      width: 348px;
      margin: 0 auto;
    }
    @media (max-width: 375px) {
      width: 230px;
      padding-left: 30px;
      padding-right: 30px;
      height: 72px;
      margin: 0 auto;
      border-radius: 41.5px;
      text-align: center;
    }
  }
}

.landing-page-popup-main {
  .landing-page-popup-container {
    @media (max-height: 850px) {
      height: 675px;
      top: 8%;
    }
    @media (max-height: 765px) {
      height: 625px;
      top: 7%;
    }
    @media (max-height: 695px) {
      height: 580px;
      top: 5%;
    }
    @media (max-height: 630px) {
      height: 530px;
      top: 5%;
    }
    @media (max-height: 575px) {
      height: 500px;
      top: 5%;
    }
    @media (max-height: 545px) {
      height: 475px;
      top: 4%;
    }

    @media (max-width: 800px) {
      top: 5%;
    }
    @media (max-width: 800px) and (max-height: 645px) {
      top: 3%;
    }
    @media (max-width: 575px) {
      width: 424px;
    }
    @media (max-width: 500px) {
      width: 400px;
      right: 7%;
    }
    @media (max-width: 450px) {
      width: 363px;
      right: 7%;
    }
    @media (max-width: 400px) {
      right: 5.5%;
      width: 330px;
    }
    @media (max-width: 355px) {
      width: 300px;
    }
  }

  .landing-page-popup-p {
    @media (max-height: 850px) {
      margin-bottom: 10px;
    }
    @media (max-height: 765px) {
      line-height: 1;
    }

    @media (max-width: 450px) {
      font-size: 15px;
    }
    @media (max-width: 400px) {
      font-size: 14px;
      text-align: center;
    }
  }

  .landing-page-popup-inner-container {
    @media (max-height: 850px) {
      padding-top: 30px;
    }
    @media (max-height: 545px) {
      padding-top: 15px;
    }

    @media (max-width: 500px) {
      width: 85%;
    }
    @media (max-width: 450px) {
      width: 90%;
    }
  }

  .landing-page-popup-inner-h3 {
    @media (max-height: 765px) {
      font-size: 32px;
      margin-bottom: 12px;
    }
    @media (max-height: 630px) {
      font-size: 30px;
      margin-bottom: 8px;
      line-height: 1;
    }
    @media (max-width: 450px) {
      font-size: 32px;
    }
    @media (max-width: 400px) {
      font-size: 30px;
      text-align: center;
    }
    @media (max-width: 355px) {
      font-size: 29px;
    }
  }

  .landing-page-popup-input {
    @media (max-height: 695px) {
      height: 45px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @media (max-height: 630px) {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    @media (max-width: 450px) {
      width: 100%;
    }
  }

  .landing-page-grid-container {
    @media (max-width: 450px) {
      width: 100%;
      grid-gap: 20px;
    }
  }

  .landing-page-popup-button {
    @media (max-height: 695px) {
      height: 50px;
    }
    @media (max-height: 630px) {
      margin-top: 10px;
    }
    @media (max-width: 355px) {
      margin-top: 10px;
    }
  }
}

.landing-page-how-it-works {
  .how-it-works-div {
    @media (max-width: 1700px) {
      grid-gap: 30px;
    }
    @media (max-width: 1250px) {
      grid-template-columns: 1fr 1.6fr;
      grid-gap: 50px;
      margin-bottom: 20px;
    }
    @media (max-width: 1080px) {
      grid-template-columns: 1fr 1.5fr;
      grid-gap: 20px;
    }
    @media (max-width: 995px) {
      grid-gap: 10px;
    }
    @media (max-width: 900px) {
      grid-gap: 30px;
    }
    @media (max-width: 675px) {
      display: block;
    }
  }

  .how-it-works-br {
    @media (max-width: 1250px) {
      display: none;
    }
  }

  .how-it-works-p {
    @media (max-width: 1200px) {
      font-size: 38px;
    }
    @media (max-width: 1140px) {
      font-size: 36px;
    }
    @media (max-width: 1015px) {
      font-size: 34px;
    }
    @media (max-width: 860px) {
      font-size: 32px;
    }
    @media (max-width: 675px) {
      margin-bottom: 15px;
      text-align: center;
    }
    @media (max-width: 550px) {
      font-size: 30px;
    }
  }

  .how-it-works-right-grid-item {
    @media (max-width: 1090px) {
      padding-top: 5px;
    }
  }

  .landing-page-how-it-works-button-container {
    @media (max-width: 965px) {
      display: grid;
      grid-template-columns: 1.2fr 1.6fr;
      grid-gap: 30px;
    }
    @media (max-width: 675px) {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }

  .landing-page-button-div {
    @media (max-width: 965px) {
      width: 100%;
      font-size: 16px;
      padding-right: 15px;
      padding-left: 15px;
      text-align: center;
      height: 65px;
      line-height: 1.3;
    }
    @media (max-width: 675px) {
      max-width: 340px;
    }
  }

  .how-it-works-info-p {
    @media (max-width: 1080px) {
      font-size: 16px;
    }
    @media (max-width: 900px) {
      font-size: 15px;
    }
    @media (max-width: 675px) {
      text-align: center;
    }
  }
}
