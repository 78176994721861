@import "./_landingPage.scss";
@import "./_digitalGiftCard.scss";
@import "./_caseStudy.scss";

input,
button,
.no-style-link {
  border: 0;
  outline: 0;
  color: inherit;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    border: 0 !important;
    outline: 0 !important;
    text-decoration: none;
    color: inherit;
  }
}

.main-margin-body-layout {
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1276px) {
    width: 80%;
  }
  @media (max-width: 995px) {
    width: 90%;
  }
}

.button-disabled {
  cursor: not-allowed;
}